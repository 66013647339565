export const analyticstagsLocalization = {
  'AnalyticsTags:Title': {
    eng: 'Metrics configuration',
    ru: 'Настройка метрик',
  },
  'AnalyticsTags:Subtitle': {
    eng: 'To get additional analytics for the project, add the necessary metrics here and then use them in the script. JAICP will use them to collect data when users communicate with your bot. For example, when they get into the script branch of a certain topic or when a specified action occurs.',
    ru: 'Чтобы получать дополнительную аналитику по проекту, добавьте сюда нужные метрики, а затем используйте их в сценарии. По ним JAICP будет собирать данные, когда пользователи будут общаться с ботом. Например, при попадании в ветку сценария определенной темы или совершении указанного действия.',
  },
  'AnalyticsTags:Subtitle:Doc': {
    eng: 'How to set up metrics',
    ru: 'Как настроить метрики',
  },
  'AnalyticsTags:Subtitle:Doc:Link': {
    eng: '/platform_ux/metrics-configuration',
    ru: '/platform_ux/metrics-configuration',
  },
  'AnalyticsTags:Footer:topics': {
    eng: 'You will find added topics here. <br> For example, the following topics may be suitable for bank customer support bot: “Card blocking”, “Loan application”.',
    ru: 'Добавьте темы, и они отобразятся здесь. <br> Например, для бота поддержки банка могут подойти темы: «Блокировка карты», «Заявка на кредит».',
  },
  'AnalyticsTags:Footer:actions': {
    eng: 'You will find added actions here. <br> The following actions may be suitable for bank customer support bot: “Card blocked”, “Transaction certificate issued”, or general actions like “Phrase not recognized”, “Transfer to an agent”.',
    ru: 'Добавьте действия, и они отобразятся здесь. <br> Например, для бота поддержки банка могут подойти «Карта заблокирована», «Получена справка по операции» или общие действия: «Фраза не распознана», «Перевод на оператора».',
  },
  'AnalyticsTags:Footer:session_results': {
    eng: 'You will find added session results here. <br> For example, “Customer issue solved”, “Bot did not help the customer”.',
    ru: 'Добавьте результаты сессий, и они отобразятся здесь. <br> Например, «Вопрос клиента решен», «Бот не помог клиенту».',
  },
  'AnalyticsTags:Footer:topics:Search': {
    eng: 'There is no such topic. Please, enter another key or value.',
    ru: 'Такой темы нет. Введите другой ключ или значение.',
  },
  'AnalyticsTags:Footer:actions:Search': {
    eng: 'There is no such action. Please, enter another key or value.',
    ru: 'Такого действия нет. Введите другой ключ или значение.',
  },
  'AnalyticsTags:Footer:session_results:Search': {
    eng: 'There is no such result. Specify another key or value.',
    ru: 'Такого результата нет. Укажите другой ключ или значение.',
  },
  'AnalyticsTags:Footer:Search': {
    eng: 'There is no such label. Specify another key or value.',
    ru: 'Такой метки нет. Укажите другой ключ или значение.',
  },
  'AnalyticsTags:Tabs:Topics': {
    eng: 'Topics',
    ru: 'Темы',
  },
  'AnalyticsTags:Tabs:Actions': {
    eng: 'Actions',
    ru: 'Действия',
  },
  'AnalyticsTags:Tabs:SessionResults': {
    eng: 'Session results',
    ru: 'Результаты сессий',
  },
  'AnalyticsTags:TagPanel:Title:topics': {
    eng: 'You can use topics to mark important script branches or states.',
    ru: 'Темы можно использовать, чтобы обозначить важные ветки и стейты сценария.',
  },
  'AnalyticsTags:TagPanel:Title:actions': {
    eng: 'You can use actions to mark important script branches or states, where users achieve some result. It can also be actions in the bot script.',
    ru: 'Действия можно использовать, чтобы обозначить ветки и стейты сценария, где пользователь достигает какой-либо результат. Это также могут быть действия в сценарии бота.',
  },
  'AnalyticsTags:TagPanel:Title:session_results': {
    eng: 'You can track the results that users achieve during the conversation with the bot. Unlike actions, there can only be one result per session.',
    ru: 'Вы можете фиксировать результаты, которые пользователи достигли в разговоре с ботом. В отличие от действий результат может быть только один в рамках одной сессии.',
  },
  'AnalyticsTags:Search:Placeholder': {
    eng: 'Key or value',
    ru: 'Ключ или значение',
  },
  'AnalyticsTags:Dropdown:DESC': {
    eng: 'Newest first',
    ru: 'Сначала новые',
  },
  'AnalyticsTags:Dropdown:ASC': {
    eng: 'Oldest first',
    ru: 'Сначала старые',
  },
  'AnalyticsTags:Dropdown:EDITABLEFIRST': {
    eng: 'Unused first',
    ru: 'Сперва неиспользованные',
  },
  'AnalyticsTags:Dropdown:NOTEDITABLEFIRST': {
    eng: 'Used first',
    ru: 'Сперва использованные',
  },
  'AnalyticsTags:Tag:Placeholder:Key': {
    eng: 'Key for the script',
    ru: 'Ключ в сценарии',
  },
  'AnalyticsTags:Tag:Placeholder:Value': {
    eng: 'Value',
    ru: 'Значение',
  },
  'AnalyticsTags:Error:RequiredField': {
    eng: 'Required field',
    ru: 'Обязательное поле',
  },
  'AnalyticsTags:Error:CharactersLimit': {
    eng: 'Text should be 100 characters or less.',
    ru: 'Максимально допустимое количество символов: 100.',
  },
  'reporter.analytics_entities.already_exists': {
    eng: 'This key is already in use. Please, enter another one.',
    ru: 'Такой ключ уже используется. Введите другой.',
  },
  'AnalyticsTags:Tooltip:Create': {
    eng: '<b>Enter</b> — add',
    ru: '<b>Enter</b> — добавить',
  },
  'AnalyticsTags:Tooltip:topics:Lock': {
    eng: 'There is data for this topic in analytics, so its key cannot be changed or deleted.',
    ru: 'По этой теме есть данные в аналитике, поэтому ее ключ нельзя изменить или удалить.',
  },
  'AnalyticsTags:Tooltip:actions:Lock': {
    eng: 'There is data for this action in analytics, so its key cannot be changed or deleted.',
    ru: 'По этому действию есть данные в аналитике, поэтому его ключ нельзя изменить или удалить.',
  },
  'AnalyticsTags:Tooltip:session_results:Lock': {
    eng: 'There is data on this result in analytics, so its key cannot be changed or deleted.',
    ru: 'По этому результату есть данные в аналитике, поэтому его ключ нельзя изменить или удалить.',
  },
};
